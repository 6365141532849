import { request } from "../util/api"

export default class ContentfulAPI {
  static getLatestRelease = () =>
    request(
      "https://cdn.contentful.com/spaces/7354fm057l5v/environments/master/entries?select=fields&content_type=releases&order=sys.updatedAt&limit=1",
      "GET",
      null,
      { Authorization: "Bearer bX-5JRZ75NW6Qzq2kuvTP6wNmYUTzBV3dmh6QCl_ycA" }
    )

  static getLatestReleaseRange = (version: string) =>
    request(
      "https://cdn.contentful.com/spaces/7354fm057l5v/environments/master/entries?select=fields&content_type=releases&fields.version[gt]=" +
        version,
      "GET",
      null,
      { Authorization: "Bearer bX-5JRZ75NW6Qzq2kuvTP6wNmYUTzBV3dmh6QCl_ycA" }
    )
}

import React from "react"

type OwnProps = {
  active?: boolean
  onClick?: () => void
  label: string
  size?: "small" | "medium" | "large"
}

function Button({ active = false, onClick, label, size = "large" }: OwnProps) {
  const className = "primary-btn " + (active ? " active " : " deactive ") + size
  return (
    <button className={className} onClick={onClick}>
      {label}
    </button>
  )
}

export default Button

import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/Layout"
import Button from "../../components/Button.component"
import { isBrowser } from "../../util"
import { FeaturesListType, Release } from "../../data/types"
import { compareVersions, validate } from "compare-versions"
import ContentfulAPI from "../../api/Contentful.api"

type OwnProps = {
  data: {
    release: Release
  }
}

const WhatsNew = ({ data }: OwnProps) => {
  const [featuresList, setfeaturesList] = useState<string[]>([])

  const currentVersion = new URLSearchParams(
    isBrowser() ? window.location.search : ""
  ).get("currentVersion")

  useEffect(() => {
    getReleases()
  }, [])

  const latestVersion = data.release.items?.[0]?.fields?.version
  const isOutdated = currentVersion
    ? validate(currentVersion) &&
      compareVersions(latestVersion, currentVersion) > 0
    : false

  const getReleases = async () => {
    try {
      if (!currentVersion || !isOutdated) {
        setReleaseNotes([data.release.items[0].fields])
      } else {
        if (isOutdated) {
          const data: { items: FeaturesListType[] } =
            await ContentfulAPI.getLatestReleaseRange(currentVersion)
          setReleaseNotes(data.items)
        }
      }
    } catch (error) {}
  }

  const setReleaseNotes = (items: FeaturesListType[]) => {
    if (items.length) {
      const notes: string[] = items.reduce((prev: string[], next) => {
        return [...prev, ...next.features]
      }, [])

      setfeaturesList(notes)
    }
  }

  const onClickUpdate = () => {
    // window.ReactNativeWebView.postMessage(JSON.stringify({ event: "update" }))
  }

  const renderUpdateNow = isOutdated ? (
    <section>
      Get the latest update
      <Button onClick={onClickUpdate} active label="Update now" size="small" />
    </section>
  ) : null

  const renderFeatures = (item: string, index: number) => {
    return <li key={index.toString()}>{item}</li>
  }

  return (
    <Layout>
      <div className="quick-guide">
        <div className="whats-new-header">
          <h3>What's new</h3>
          <label>
            Find out what's new in our latest version {latestVersion}
          </label>
          <br />

          {renderUpdateNow}
        </div>
        <ul>{featuresList.map(renderFeatures)}</ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetLatestRelease {
    release {
      items {
        fields {
          version
          features
        }
      }
    }
  }
`

export default WhatsNew
